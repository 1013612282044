import React from "react"
import FotoCasaRossello from "@components/pageCasosdeExito/image/imgPhotoEmpresa/casaRossello/FotoCasaRossello.png"
import FotoCasaRossello2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/casaRossello/FotoCasaRossello2.png"

//logo de empresas clientes
import CasaRossello from "@components/pageCasosdeExito/image/logoscustomer/CasaRossello.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import livechat from "@components/pageCasosdeExito/image/iconcards/livechat.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"


//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/casarossello/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/casarossello/img3.svg"

const text1 = (
  <span className="">
    Casa Rosselló, es una compañía con más de 150 años de historia en el sector
    construcción y acabados, que fabrican y comercializan porcelanatos,
    revestimientos, piedras naturales, cerámicos y más para diferentes
    verticales.
    <br /> <br />
    Con Beex, la compañía ha logrado aumentar
    en +75% sus leads desde canales digitales, gracias a las acciones de
    contacto rápido, como WhatsApp Business API y chat en vivo. Además de
    prospectarlos de forma más óptima.
  </span>
)

const text2 = (
  <span>
    Casa Rosselló, empresa peruana que fabrica y comercializa acabados de
    construcción, son referentes de este sector en el mercado, siendo una de las
    marcas más reconocidas en esta industria.
    <br /> <br />
    Desde 1870 trabajan con diferentes tipos de productos, como mármoles,
    piedras naturales, porcelanatos, gres, cerámicos y mucho más. Es la marca de
    mayor reputación en este sector, ya que trabajan cada proyecto de forma
    personalizada con la intención de ayudar a sus clientes a resolver y
    desarrollarlos completamente, con altos estándares de calidad.
    <br /> <br />
    En 2022, la empresa decidió mejorar sus procesos de comunicación con sus
    clientes por sus canales digitales. Por ello, buscó una plataforma omnicanal
    que pueda integrar sus canales más importantes, además que la experiencia de
    uso sea la ideal, ya que trabajan con ejecutivos comerciales que tienen un
    trabajo de acompañamiento muy personalizado, por lo que la experiencia móvil
    fue un punto a tener en cuenta.
    <br /> <br />Y así decidieron por Beex. Con nuestra plataforma omnicanal
    en la nube, Casa Rosselló ha sido capaz de integrar sus canales más
    importantes de comunicación, como WhatsApp, Messenger, Instagram y chat en
    vivo. Además de potenciar sus procesos de atención y marketing con los
    chatbots de la plataforma.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Con Beex ahora todo es más ordenado. Hemos sido capaces de automatizar la
    asignación de leads y clientes hacia nuestros asesores de ventas, conocer
    muy bien los tipos de consultas que nos realizan y tener historiales
    completos omnicanales.”
  </p>
)

const text4 = (
  <span>
    Marlen Vega, Jefa de Marketing en Casa Rosselló, ha sido el contacto directo
    para la puesta en producción de nuestra plataforma en sus diferentes operaciones, como
    atención al cliente, marketing y ventas.
    <br /> <br />
    Como bien menciona Marlen, con nosotros han conseguido centralizar todas las
    conversaciones de sus clientes en un solo lugar, considerando que no
    solamente tienen contacto por WhatsApp como canal de vital importancia, sino
    por otros, como son los chats de redes sociales.
  </span>
)

const text5 = (
  <span>
    Casa Rosselló, como marca reconocida en el mercado, cuenta con interacciones
    por diferentes canales, como WhatsApp y redes sociales. Además, al tener una
    operación de marketing con objetivos claros, tanto para redes, medios
    sociales y publicidad pagada, cuentan con mucho tráfico a su web, por lo que
    integraron y han sacado provecho al canal de webchat de Beex.
    <br /> <br />
    Sobre esto, Marlen nos cuenta que la experiencia de usuario con el producto
    ha sido algo importante para todos sus agentes de atención al cliente y
    comerciales. Pudieron integrar sin problemas el chat en vivo de Beex, y
    así unificar todas sus comunicaciones.
    <br /> <br />
    Gracias al contacto directo con los usuarios de su web, Casa Rosselló ha
    logrado aumentar la cantidad de leads digitales con respecto a periodos
    pasados y prospectarlos mejor y más rápido con chatbots. Sin duda, tener un
    canal de chat en vivo ha hecho que los visitantes de diferentes fuentes
    hacia su web, puedan resolver sus dudas y obtener mejor información de forma
    más óptima.
  </span>
)
const text6 = (
  <span>
    “Nuestra cantidad de leads por digital ha aumentado en +75% desde que
    implementamos livechat en nuestra web. Ahora los ayudamos más rápido con
    nuestro bot, sin necesidad de enviarlos por otro canal alterno.”
  </span>
)

const text7 = (
  <span>
    Contar con el API oficial de WhatsApp Business ha ayudado a la marca en
    fidelizar aún más con sus clientes, ya que usan un número central
    certificado para comunicarse con ellos, lo que hace que no tengan diferentes
    puntos de contacto por este canal.
    <br /> <br />
    Además, con la ayuda del equipo de Customer Success, Casa Rosselló
    ha logrado obtener el check verde para su cuenta oficial. Esto, sin duda
    alguna, ha hecho que no solo sus clientes, sino también sus contactos y
    leads en general, confíen en la comunicación que la marca brinda por este
    canal, tanto para comunicaciones bidireccionales, como para campañas
    masivas.
    <br /> <br />
    Respecto a esto último, Marlen nos cuenta que la experiencia que han tenido
    con WhatsApp como canal para envíos de campañas masivas ha sido excelente.
    Es sabido que WhatsApp maneja mejor tasa de apertura y conversión respecto a
    otros canales, como por ejemplo correo electrónico, pero esto lo han
    confirmado en Casa Rosselló con creces.
  </span>
)
const text8 = (
  <span>
    “Las campañas masivas por WhatsApp son fantásticas. Es mucho más fácil
    gestionarlas cada que tenemos algún tipo de promoción, descuentos especiales
    o incluso encuestas. Llegamos a muchos contactos con información que sabemos
    leerán. Esto ha hecho que mejoremos en +65% nuestra recompra.”
  </span>
)
const text9 = (
  <span>
    Al contar con muchos ejecutivos comerciales, Casa Rosselló necesitaba una
    plataforma que les brinda una solución multiagente eficiente, y que de paso
    los ayude a monitorear y auditar las interacciones con sus clientes, para
    fines de calidad.
    <br /> <br />
    La opción multiagente de Beex ha sido de gran ayuda para empezar a darle
    visibilidad a WhatsApp. Ahora, desde su número certificado, la empresa es
    capaz de aumentar o disminuir agentes cada vez que lo requieran. Además de
    administrar sus colas de atención multiagente para sus diferentes
    operaciones.
    <br />
    <br />
    Esto, más las características nativas de la plataforma, como plantillas,
    respuestas rápidas, campos personalizados y más, han hecho que puedan
    sacarle provecho al canal con características que únicamente encontraban en
    su CRM.
  </span>
)
const text10 = (
  <span>
    “Hemos logrado que nuestros asesores de ventas tengan un mismo número de
    atenciones para que puedan generar ventas o cotizaciones. Es una gran forma
    de dividir las tareas del área comercial. Además, son capaces de compartir
    estados de pedidos, números de cuenta e info general con un par de clicks.”
  </span>
)
const text11 = (
  <span>
    Algo que podemos afirmar desde Beex, es que nuestros procesos de
    Service Desk son muy agradecidos por nuestros clientes y esto es algo que en
    Casa Rosselló han confirmado.
    <br />
    <br />
    Desde la implementación, el equipo de Service Desk ha ayudado a diseñar e
    implementar los flujos conversaciones de Casa Rosselló para sus chatbots,
    hasta el acompañamiento diario para consultas o dudas.
    <br />
    <br />
    Como menciona Marlen, ha sido una gran experiencia, ya que tienen el soporte
    y orientación necesaria para sacarle provecho a nuestra plataforma. Además, que el
    tiempo de respuesta por nuestros canales de atención siempre es el ideal.
  </span>
)

const text12 = (
  <span>
    “Desde el inicio, el equipo de Beex nos ha tenido paciencia para la
    salida y uso de la plataforma. Hemos pasado por un proceso de adopción por
    parte de nuestro equipo y siempre han tenido una buena disposición para dar
    charlas y tips de cómo sacarle provecho a la solución.”
  </span>
)

const text13 = (
  <p>
    Estos son algunos procesos con los que Beex ayuda
    a Casa Rosselló en su operación de atención al cliente, marketing ventas:
  </p>
)
const text14 = (
  <p>
    Estamos orgullosos de acompañar a una marca tan reconocida en
    el mercado como Casa Rosselló. Sin duda, ha sido una experiencia
    enriquecedora para ambas operaciones, siempre con la mejor disposición bajo
    una sola meta: Mejorar la operación digital de la marca para obtener mejores
    resultados.
  </p>
)

const data = {
  intro: {
    image: CasaRossello,
    link: "#",
    classlogo: "logo__clientes--casa",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoCasaRossello,
    text4: text4,
    title5: "Marketing y ventas con livechat ",
    text5: text5,
    text6: text6,
    title7: "Campañas masivas de WhatsApp Marketing ",
    text7: text7,
    text8: text8,
    title9: "WhatsApp multiagente",
    text9: text9,
    text10: text10,
    title11: "Acompañamiento exclusivo ",
    text11: text11,

    fototwo: FotoCasaRossello2,
    text12: text12,
    text13: text13,
    text14: text14,

    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial certificado de WhatsApp Business API para sus operaciones. Beex logra que sus clientes conversen con Casa Rosselló mediante la App más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más.",
    },
    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots por los canales de más interacción con sus clientes, como WhatsApp y Facebook Messenger. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "Livechat",
      img: livechat,
      info:
        "Chat directo en las páginas web de Casa Rosselló. Integración simple y rápida sin necesidad de tocar código de forma avanzada. Además, automatización del canal con chatbots personalizados para cada landing de la web. ",
    },
    {
      title: "Campañas masivas: ",
      img: campañas,
      info:
        "Envíos de campañas masivas de WhatsApp para su operación de Marketing. Proceso simple y rápido con el uso de plantillas pre-aprobadas de WhatsApp. Mejora en sus KPIs más importentes: Open y conversion rate. ",
    },
  ],
}

export default data
