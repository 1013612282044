/* eslint-disable no-undef */
import React from "react"
import StructurePageCostagas from "@components/pageCasosdeExito/template/templatePageCasaRossello"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: Casa Rossello [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/casa-rossello/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Casa Rosselló ha centralizado sus canales digitales en Beex, plataforma omnicanal, logrando mejorar sus ventas y tasa de recompra mensual." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: Casa Rossello" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/casa-rossello/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-rossello.jpg`}
      />
      <meta property="og:description" content="Casa Rosselló ha centralizado sus canales digitales en Beex, plataforma omnicanal, logrando mejorar sus ventas y tasa de recompra mensual." />
    </Helmet>
    <StructurePageCostagas location={location} />
  </div>
)

export default IndexPage
